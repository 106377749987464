<template>
    <client-page>
        <sub-visual tit="납품사례" bg="/images/sub/visual/sv--business.jpg"></sub-visual>

        <section class="section">
            <v-container>
                <table class="board-view">
                    <tr>
                        <th colspan="2">{{product.subject}}</th>
                    </tr>
                    <tr>
                        <td colspan="2" class="content_box">
                            <div v-html="product.content"></div>
                        </td>
                    </tr>
                </table>
                <table class="board-view-navi mt-20 mt-lg-40">
                    <tr>
                        <th>이전글</th>
                        <td><a :href="prevBoard._id" class="d-block ellip">{{prevBoard.subject}}</a></td>
                    </tr>
                    <tr>
                        <th>다음글</th>
                        <td><a :href="nextBoard._id" class="d-block ellip">{{nextBoard.subject}}</a></td>
                    </tr>
                </table>
                <div class="d-flex justify-end mt-50 mt-lg-100">
                    <v-btn outlined @click="link" color="grey-7">LIST</v-btn>
                </div>
            </v-container>
        </section>

        <!-- <section class="section">
            <v-card-subtitle>{{product.subject}}</v-card-subtitle>
            <v-container>
                <div v-html="product.content"></div> 
            </v-container>
        </section>
        <div role="table" class="board-table board-table--preview">
            <div role="rowgroup">
                <div role="row">
                    <div role="columnheader">
                        이전글
                    </div>
                    <div role="cell">
                        <div class="title"><a :href="prevBoard._id">{{prevBoard.subject}}</a></div>
                    </div>
                </div>
                <div role="row">
                    <div role="columnheader">
                        다음글
                    </div>
                    <div role="cell">
                        <div class="title"><a :href="nextBoard._id">{{nextBoard.subject}}</a></div>
                    </div>
                </div>
            </div>
        </div>

        <div class="board-buttons">
            <div class="left">
                <a class="button button--border" @click="link"><span>리스트</span></a>
            </div>
        </div> -->
    </client-page>
</template>

<script>
import ClientPage from "@/pages/client/templates/ClientPage.vue";
import SubVisual from "@/components/client/sub/sub-visual.vue";
import BtnPrimary from "@/components/dumb/btn-primary.vue";

import api from "@/api";

export default {
    components: {
        ClientPage,
        SubVisual,
        BtnPrimary,
    },
    data() {
        return {
            product: null,
            boards: [],
            prevBoard: null,
            nextBoard: null,
            show: true,
        }
    },
    mounted() {
        this.init();
    },
    methods: {
        async init() {
            this.code = this.$route.params.business;
            let { board } = await api.v1.boards.get({ _id: this.$route.params.business });
            this.product = board;

            let { boards } = await api.v1.boards.gets({ params: { code: "delivery" }})
            this.boards = boards;

            let boardIdx = this.boards.findIndex(el => el["_id"] === this.$route.params.business) 
            boardIdx === 0 ? this.prevBoard = this.boards : this.prevBoard = this.boards[boardIdx-1]
            boardIdx === this.boards.length-1 ? this.nextBoard = this.boards : this.nextBoard = this.boards[boardIdx+1]
        },
        link() {
            this.$router.push("/business")
        }
    },
};
</script>

